import * as React from "react"
import '../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../css/bootstrap.css";
import "../css/template.css";
import "../css/responsive.css";
import "../css/base-sizing.css";
import "../css/custom.css";

import HeaderAndMenu from "../page_components/common/HeaderAndMenu";
import Footer from "../page_components/common/Footer";
import BsHelmet from "../page_components/common/BsHelmet";
import CommonHero from "../page_components/common/CommonHero";
import SecuritySection from "../page_components/common/SecuritySection";



const IndexPage = ({data}) => {
    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"Security"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHero title="Security"/>
                <SecuritySection/>


                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default IndexPage
